/** @format */

import React from "react";

import Container from "../../atoms/Container/Container";
import OfersContactSection from "../../molecules/OfersContactSection/OfersContactSection";

import { StyledText } from "../../atoms/StyledText/StyledText";

import {
  StyledContactMapSection,
  StyledLeftWrapper,
  StyledRightWrapper,
} from "./StyledContactMapSection";

const ContactMapSection = ({
  title,
  tel,
  email,
  add1,
  add2,
  tel2,
  title2,
  tel1,
  tel22,
  email2,
  name,
}) => {
  return (
    <Container>
      <StyledContactMapSection>
        <StyledLeftWrapper>
          <StyledText
            hasdeclaredfontcolor="#000"
            hasdeclaredfontsize="clamp(32px, 2.788vw, 56px)"
            hasdeclaredfontweight="700"
            hasdeclaredmargin="0 0 clamp(32px, 2.788vw, 56px) 0"
          >
            {title}
          </StyledText>
          <div>
            <StyledText>
              Tel: <span>{tel}</span>
            </StyledText>
            <StyledText>
              Tel: <span>{tel2}</span>
            </StyledText>
            <StyledText>
              E-mail: <span>{email}</span>
            </StyledText>
            <StyledText>
              Adres: <span>{add1 + " " + add2}</span>
            </StyledText>
          </div>
          <div>
            <OfersContactSection
              title={title2}
              tel1={tel1}
              tel2={tel22}
              email={email2}
              name={name}
            />
          </div>
        </StyledLeftWrapper>
        <StyledRightWrapper>
          <iframe
            title="google map"
            src="https://maps.google.com/maps?q=Kisielsk%2038,%2021-450%20Kisielsk&t=&z=13&ie=UTF8&iwloc=&output=embed"
            style={{ border: "none" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </StyledRightWrapper>
      </StyledContactMapSection>
    </Container>
  );
};

export default ContactMapSection;
