/** @format */

import React from "react";
import { graphql } from "gatsby";

import Banner from "../components/molecules/Banner/Banner";
import ContactMapSection from "../components/organisms/ContactMapSection/ContactMapSection";
import FormSection from "../components/organisms/FormSection/FormSection";
import HelmetTemplate from "../components/molecules/HelmetTemplate/HelmetTemplate";
import Container from "../components/atoms/Container/Container";

const Contact = ({
  data: {
    datoCmsKontakt: {
      adresLinijkaDruga,
      adresLinijkaPierwsza,
      email,
      zdjCieGWne,
      tytu,
      telefon,
      telefon2,
      tytuNaZdjCiuGWnym,
      emailOfertowanie,
      telefon2value,
      telefon1,
      tytuOfertowania,
      imi
    },
  },
}) => {
  return (
    <main>
      <HelmetTemplate
        title="Kontakt"
        desc="Skontaktuj się z firmą Kon-stal, tworzymy i budujemy dla Ciebie"
      />
      <Container>
        <Banner image={zdjCieGWne} text={tytuNaZdjCiuGWnym} />
        <ContactMapSection
          title={tytu}
          tel={telefon}
          tel2={telefon2}
          email={email}
          add2={adresLinijkaDruga}
          add1={adresLinijkaPierwsza}
          title2={tytuOfertowania}
          tel1={telefon1}
          tel22={telefon2value}
          email2={emailOfertowanie}
          name={imi}
        />
        <FormSection />
      </Container>
    </main>
  );
};

export default Contact;

export const query = graphql`
  query contactPageQuery {
    datoCmsKontakt {
      adresLinijkaDruga
      adresLinijkaPierwsza
      email
      telefon
      telefon2
      zdjCieGWne {
        alt
        title
        gatsbyImageData
      }
      tytu
      tytuNaZdjCiuGWnym
      emailOfertowanie
      telefon2value
      telefon1
      tytuOfertowania
      imi
    }
  }
`;
