import styled from "styled-components"

export const StyledTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
        font-size: clamp(18px, 1.25vw, 24px);
        font-weight: 700;
        line-heigth: 1.35em;

        span {
            font-weight: 500;
        }
    }
`