import styled from "styled-components";

export const StyledContactMapSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 44px clamp(22px, 9.583vw, 184px) clamp(64px, 5.333vw, 98px);
  gap: clamp(40px, 4.167vw, 80px);

  @media only screen and (max-width: 992px) {
    padding-left: 22px;
    padding-right: 22px;
    gap: 40px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledLeftWrapper = styled.div`
  width: 40%;

  > div {
    display: flex;
    flex-direction: column;
    gap: 20px;

    > p {
      font-size: clamp(18px, 1.25vw, 24px);
      font-weight: 700;
      line-heigth: 1.35em;

      span {
        font-weight: 500;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;

export const StyledRightWrapper = styled.div`
  width: 60%;

  iframe {
    width: 100%;
    height: 400px;
    border-radius: 50px;
  }

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;
