import React from 'react'

import { StyledText } from "../../atoms/StyledText/StyledText";
import { StyledTextWrapper } from "./StyledOfersContactSection";

const OfersContactSection = ({ title, tel1, tel2, email, name }) => {
  return (
    <div>
        <StyledText
            hasdeclaredfontcolor="#000"
            hasdeclaredfontsize="clamp(24px, 1.688vw, 32px)"
            hasdeclaredfontweight="700"
            hasdeclaredmargin="clamp(32px, 2.788vw, 56px) 0 clamp(16px, 1.288vw, 24px) 0"
          >
            {title}
        </StyledText>
        <StyledTextWrapper>
            <p>{name}</p>
            <StyledText>
              E-mail: <span>{email}</span>
            </StyledText>
            <StyledText>
              Tel: <span>{tel1}</span>
            </StyledText>
            <StyledText>
              Tel: <span>{tel2}</span>
            </StyledText>
        </StyledTextWrapper>
    </div>
  )
}

export default OfersContactSection